<template>
  <div>
    <main class="router-view-container">
      <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

      <div v-if="is_API_loaded" class="space-y-5">
        <!-- 頁首 -->
        <section class="flex flex-col sm:flex-row space-y-4 sm:space-y-0">
          <!-- 標題區塊 -->
          <div class="sm:pt-4 w-full sm:w-1/3 flex-shrink-0">
            <h3 class="h3 mb-4">頁首選單</h3>
            <p>顯示在網站上的主要導航選單。</p>
          </div>

          <!-- 選單項目 -->
          <router-link :to="{ name: 'menus-header' }" class="box">
            <div class="flex items-center justify-between px-4">
              <h3 class="h3">選單項目</h3>
              <span class="text-success">編輯選單</span>
            </div>
            <hr />
            <div class="space-y-4" v-if="lists.tarr.length > 0">
              <p class="list_item" v-for="(item, index) in lists.tarr" :key="index">{{ item.title }}</p>
            </div>
            <div v-else>
              <p class="text-center text-gray-400">尚無任何選單</p>
            </div>
          </router-link>
        </section>

        <hr />

        <!-- 頁尾 -->
        <section class="flex flex-col sm:flex-row space-y-4 sm:space-y-0">
          <!-- 標題區塊 -->
          <div class="sm:pt-4 w-full sm:w-1/3 flex-shrink-0">
            <h3 class="h3 mb-4">頁尾選單</h3>
            <p>顯示在網站底部的導航選單。</p>
          </div>

          <!-- 選單項目 -->
          <router-link :to="{ name: 'menus-footer' }" class="box">
            <div class="flex items-center justify-between px-4">
              <h3 class="h3">選單項目</h3>
              <span class="text-success">編輯選單</span>
            </div>
            <hr />

            <div class="space-y-4" v-if="lists.farr.length > 0">
              <p class="list_item" v-for="(item, index) in lists.farr" :key="index">{{ item.title }}</p>
            </div>
            <div v-else>
              <p class="text-center text-gray-400">尚無任何選單</p>
            </div>
          </router-link>
        </section>
      </div>
      <!-- 骨架屏 -->
      <div v-else class="space-y-4">
        <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0" v-for="(item, index) in 2" :key="index">
          <div class="w-1/3 flex-shrink-0 space-y-4">
            <skeleton class="mb-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="170px" height="10px" />
            <skeleton width="140px" height="10px" />
          </div>

          <div class="flex flex-col w-full space-y-4 px-4">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
            </div>
            <skeleton v-for="(item, index) in 5" :key="index" width="100%" height="10px" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'menus',
  data() {
    return {
      is_API_loaded: false, // 判斷並呈現骨架屏
      lists: [],
    }
  },
  created() {
    this.getLists()
  },
  methods: {
    // 2.2.1.1 讀取目錄清單列表
    getLists() {
      this.$axios({
        url: 'front/menu/getMenuList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.lists = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-base font-medium text-gray-text_dark;
}

.box {
  @apply w-full bg-white rounded-xl py-4 cursor-pointer space-y-4 hover:shadow-xl transition-shadow duration-300;
}

.list_item {
  @apply ml-7 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}
</style>
